import React, { SyntheticEvent, useState } from 'react'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import { Form, Search } from 'semantic-ui-react'
import type { SearchProps, SearchResultData } from 'semantic-ui-react'

import { getDeckSearchOrderingQueryParameter } from 'services/accountSettings.service'

import { useActionless } from 'redux/hooks'
import { SET_ACTIVE_STATE } from 'redux/active/actions/types'

import { SearchContainer, StyledResult, DefaultLabel } from './style'
import { generateTabState } from 'types/searchV2'

/**
 * Renders the autocomplete results for the multisearch. If it is the "All decks" search option it renders with a label
 * that indicates that it is the default search.
 * @param title
 * @param description
 */
const resultRenderer = ({ title, description }: any) => (
  <StyledResult>
    <b>{title}:</b> <i>{description}</i>
    {title === 'All decks' && <DefaultLabel>Default</DefaultLabel>}
  </StyledResult>
)

/**
 * This function simply generates the semantic results array for the autocomplete which is the current search value
 * with the different search options. If the user is authenticated, it provides the option of searching through just
 * their decks.
 */
const resultGenerator = (search: string, auth = false) => {
  if (!search) return []

  const results = [
    {
      title: `All decks`,
      description: search,
    },
    {
      title: `My decks`,
      description: search,
    },
    {
      title: `Cards`,
      description: search,
    },
    {
      title: `Users`,
      description: search,
    },
  ]

  // If they're not logged in remove "My Decks"
  if (!auth) results.splice(1, 1)

  return results
}

/**
 * This is the search input that is used on the homepage. The autocomplete results simple display the current search
 * value along with the different search options. Selecting a result will redirect the user to that search page with
 * that search value. Searching without selecting will use the default search (All decks).
 */
const MultiSearch = () => {
  const router = useRouter()

  const [{ tbUser: username }] = useCookies(['tbUser'])

  const [setActiveState] = useActionless(SET_ACTIVE_STATE)

  const [value, setValue] = useState('')

  const handleSearchChange = (_e: SyntheticEvent, data: SearchProps) => {
    setValue(data.value || '')
  }

  /**
   *  This function is called when one of the autocomplete options is selected. Depending on which type of search that
   *  is, it redirects the user to the proper search page.
   */
  const handleResultSelect = (_e: SyntheticEvent, data: SearchResultData) => {
    if (!data.value) return
    if (data.result.title === 'All decks')
      router.push(`/search/decks?name=${data.value}&${getDeckSearchOrderingQueryParameter()}`)
    else if (data.result.title === 'My decks')
      router.push(
        `/search/decks?name=${data.value}&owner=${username}&ownerexact=true&${getDeckSearchOrderingQueryParameter()}`,
      )
    else if (data.result.title === 'Users') router.push(`/search/users?username=${data.value}`)
    else if (data.result.title === 'Cards') {
      const newSearchTab = generateTabState.scrySearch()

      // @ts-ignore
      newSearchTab.meta.query = data.value

      setActiveState({ searchOpen: newSearchTab })
    }
  }

  return (
    <SearchContainer>
      <Form
        onSubmit={() => !!value && router.push(`/search/decks?name=${value}&${getDeckSearchOrderingQueryParameter()}`)}>
        <Search
          onResultSelect={handleResultSelect}
          onSearchChange={handleSearchChange}
          placeholder="Search decks, cards and users..."
          size="big"
          resultRenderer={resultRenderer}
          noResultsMessage="There are no cards with that name."
          results={resultGenerator(value, !!username)}
          value={value}
        />
      </Form>
    </SearchContainer>
  )
}

export default MultiSearch
